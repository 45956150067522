import React, { Component } from 'react'
import Layout from '../components/layout/layout'
import SEO from '../components/seo'
import Banner from '../components/banner';
import CategoryType from '../components/category-type';
import Product from '../components/product';
import "../components/product.css"
import CategoryInformation from '../components/categoryInformation';
import { StaticQuery, graphql } from 'gatsby'
import KitchenShapeSelection from '../components/kitchen-shape-selection';
import SeoText from '../components/browse-designs-seo-text';
import { BrowseDesignsSeoText, KitchenTags } from '../components/layout/strings';

class Kitchen extends Component {
  constructor(props){
    super(props);
  }

  render(){
    return(
      <Layout selectedPage = "browseDesigns">
          <SEO 
            title={KitchenTags.title}
            description={KitchenTags.description} />

          <div>
            {KitchenBanner}
    
            <div className="roomsAndFilterSelection">
              <CategoryType selectedCategory="Kitchen"/>
            </div>
      
            {/* <KitchenShapeSelection />

            <SeoText data = {BrowseDesignsSeoText.kitchen}/> */}
          </div>
      </Layout>
      )
    }
  }

const KitchenBanner =
  <StaticQuery
    query={graphql`
    query {
      banner: file(relativePath: { eq: "banner_kitchen.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1980) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
    `}
    render={data => <Banner image={data.banner.childImageSharp.fluid}/>}
    />;

export default Kitchen
