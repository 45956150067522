import React, { Component } from "react";
import './kitchen-shape-selection.css';

const KitchenShapeData ={
    title: "SELECT KITCHEN INTERIOR LAYOUT",
    subTitle: "Now redefining you Kitchen Interiors is made easy with 5 simple steps",
    shapes: [
        "Straight",
        "U Shaped",
        "L Shaped",
        "Parallel",
        "Straight Island",
        "U Shaped Island",
        "L Shaped Island",
        "Parallel Island",
    ]
}

class KitchenShapeSelection extends Component{
    constructor(props){
        super(props);
    }

    selectShape(shape){
        console.log(shape);
        let shapeCheckbox= document.getElementById(shape);
        shapeCheckbox.checked = !shapeCheckbox.checked;
    }

    render(){
        return (
            <>
            <div className="KitchenShapeSelectionContainer">
                <h1 className="KitchenShapeSelectionHeaderText">{KitchenShapeData.title}</h1>
                <p className="KitchenShapeSelectionSubHeaderText">{KitchenShapeData.subTitle}</p>

                <div className="KitchenShapeSelectionChoiceContainer">
                    {KitchenShapeData.shapes.map(shape => 
                        {
                            return(
                                <div className="KitchenShapeChoice" onClick={e => this.selectShape(shape)}>
                                    <img className="KitchenShapeImage" />

                                    <div className="KitchenShapeCheckboxContainer">
                                        <input  id={shape}  className="KitchenShapeCheckbox" type="radio" name={shape}/>
                                        <label className="KitchenShapeCheckboxLabel" for={shape}>{shape}</label>
                                    </div>
                                </div>
                            );
                        }
                    )}
                </div>
            </div>

            <div className="KitchenShapeSelectionContainerDivider"></div>
            </>
        );
    }
}

export default KitchenShapeSelection;